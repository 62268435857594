.profile-icon {
  background: #4CAF50;
  border-radius: 50%;
  padding: 2px 4px;
  color: white;
}

.profile-icon svg {
  font-size: 1.3em;
}

.profile-name {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
}

.keyboard-down-arrow-icon {
  color: gray;
}

.logout-button {
  font-weight: 700;
}

span.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #4CAF50 !important;
}

.language-item {
  cursor: pointer;
}

.menu-list {
  position: relative !important;
  height: 100%;
}

.tour-button {
  position: absolute;
  bottom: 20px;
  left: 0px;
}

.store-name{
  display: flex;
  justify-content:center;
  align-items: center;
}