.manage-staff-container{
    display: flex;
    gap:15px;
    /* background-color: #fff; */
    margin-top: 10px;
    width: 100%;
    height: 75vh;
}

.wrap-store-text-area{
    padding: 0px 32px;
    margin-bottom: 20px;
}