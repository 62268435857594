.empty-view-wrap{
    text-align: center;
}

.contained-empty-btn{
    background-color: #FF9D0B !important;
    box-shadow: none !important;
    font-size: 13px;
    font-weight: 600 !important;
    font-family: "Work Sans", sans-serif !important;
}