/* .logout-div{
} */
.profile-button{
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    justify-content: center;
    height: 4rem;
    cursor: pointer;
}