.login-your-account {
  color: white;
  font-size: 31px;
  font-weight: 900;
  line-height: 38px;
  letter-spacing: 0.1em;
  margin: 0 0 30px;
  position: relative;
  z-index: 1;
}
.login-your-account::before {
  background: #41051e;
  content: "";
  width: 100px;
  height: 100px;
  left: -30px;
  top: -46px;
  position: absolute;
  border-radius: 100px;
  z-index: -1;
  display: none;
}
form.login-form span.login-your-account {
  font-size: 24px;
  display: block;
  text-transform: uppercase;
  text-align: center;
}
.loginimg-div{
  display: none;
 justify-content: center;
  
}
.login-img {
  width: 144px;
  height: 144px;
  display: flex;
  justify-content: center;
}

/* input {
  text-indent:30px;
} */
.formPassword{
  text-indent:0px !important;
}
.close-icon{
  position: absolute;
  right: 20px;
  top: 51px;
}
.forgot-password-button{
    background: none;
    color:white;
    font-weight: 700;
    border: none;
    cursor: pointer;
}
.forgot-button-div{
    text-align: right;
    width: 100%;
}
form.login-form {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 20vw;
  padding: 0 50px;
}