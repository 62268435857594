.invoice-container {
    padding: 30px 50px 50px;
}

.invoice-user-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.invoice-content-table {
    margin-top: 30px;
}

.invoice-comments {
    margin-top: 30px;
}

.invoice-footer-wrap{
    margin-top: 30px;
}

.invoice-text{
    font-family: "Work Sans", sans-serif;
    font-weight: 500;
}

.invoice-comments-data{
    margin-left: 50px;
    margin-bottom: 8px;
}
.invoice-comment-text{
    font-family: "Work Sans", sans-serif;
    text-transform:capitalize;
    font-size: 14px;
}

.invoice-font-weight{
    font-weight: 700;
}