.employee-info-wrap {
    /* background-color: #fff; */
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 75%;
    overflow-y: scroll;
    height: 75vh;
}

.menu-employee-wrap {
    background-color: #fff;
    margin-top: 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    position: sticky;
    top: 0px;
    z-index: 1;
}



.wrap-services-staff-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    /* height: 28%; */
}

.weekly-calender-wrap {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
}

.date-text {
    font-family: "Work Sans", sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #041e2d;
}